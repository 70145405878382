import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import '@/utils/typo.css'
import 'mavon-editor/dist/css/index.css'
import '@/assets/css/color.css'
import 'echarts'
import ECharts from 'vue-echarts'
import axios from 'axios'
import { VueTypedJs } from 'vue-typed-js'
import mavonEditor from 'mavon-editor'
import common from './utils/common'
import './assets/css/animation.css'
import '@/assets/font/font.css'
Vue.use(mavonEditor)
Vue.use(VueTypedJs)
//把 `axios` 加到 `Vue` 的原型中
Vue.prototype.$http = axios;
Vue.prototype.$common = common
Vue.component('VueEcharts', ECharts)
export const EventBus = new Vue()
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
