import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        tags: [
            {name: '首页', path: 'dataDetails', closable: false, type: ''}
        ],
        ipData: '',
        address: ''
    },
    getters: {},
    mutations: {
        addTagList(state, activePath) {
            const {path, name} = activePath
            state.tags.push({name, path, type: 'danger', closable: true})
        },
        delTagList(state, index) {
            state.tags.splice(index, 1)
        },
        // closeTagList(state){},
        openTagList(state, index) {
            state.tags.map((item, index) => {
                state.tags[index].type = ""
            })
            state.tags[index].type = "danger"
        },
        setIpData(state, res) {
            state.ipData = res
        },
        setAddress(state, res) {
            state.address = res
        },
    },
    actions: {
        setIpData(context) {
            Axios.get('https://www.mxnzp.com/api/ip/self?app_id=wkgsk6noukifzjuo&app_secret=RitNM0lyM2wxeGljbmRYN25TV0dMZz09')
                .then((res) => {
                    context.commit('setIpData', res.data.data.ip)
                    context.commit('setAddress', `${res.data.data.province}_${res.data.data.city}`)
                })
        }
    },
    modules: {}
})
