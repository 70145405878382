<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {},
}
</script>

<style lang="less">
#app {
  font-family: var(--family);
}
</style>
